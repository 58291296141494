<template>
  <div>
    <b-card>
      <b-row class="align-items-end">
        <b-col md="6">
          <label
            for="search"
          >Pesquisar</label>
          <b-form-input
            id="state"
            v-model="search"
          />
        </b-col>
        <b-col
          md="6"
          class="align-bottom"
        >
          <b-link
            :to="{ name: 'admins-add' }"
          >
            <b-button
              class="float-right"
              variant="success"
            >
              Novo Administrador
            </b-button>
          </b-link>
        </b-col>
      </b-row>
    </b-card>

    <b-overlay
      :show="deleteWarning"
      rounded="sm"
    >
      <b-card
        no-body
        class="mb-0"
      >
        <b-table
          :items="$store.state.admins.clients"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="No matching records found"
          :sort-desc.sync="isSortDirDesc"
        >

          <template #cell(actions)="data">
            <b-dropdown
              variant="link"
              no-caret
              :right="$store.state.appConfig.isRTL"
            >

              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>
              <b-dropdown-item :to="{ name: 'admins-edit', params: { id: data.item.id } }">
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">Edit</span>
              </b-dropdown-item>

              <b-dropdown-item
                @click="validarDelete(data.item.id)"
              >
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">Delete</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>

        </b-table>

        <div class="mx-2 mb-2">
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <p class="m-0">
                <span class="font-weight-bold">{{ $store.state.admins.clients.length }} </span> de <span class="font-weight-bold"> {{ $store.state.admins.total }}</span>
              </p>
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >

              <b-pagination
                v-model="currentPage"
                :total-rows="$store.state.admins.total"
                :per-page="$store.state.admins.perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>

            </b-col>

          </b-row>
        </div>
      </b-card>
      <template #overlay>
        <div class="text-center">
          <b-icon
            icon="stopwatch"
            font-scale="3"
            animation="cylon"
          />
          <p id="cancel-label">
            Você quer deletar o administrador: <b>{{ toDelete.email }}</b> ?
          </p>
          <b-button
            ref="delete"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            class="mx-1"
            variant="danger"
            size="sm"
            aria-describedby="cancel-label"
            @click="deletar"
          >
            Deletar
          </b-button>
          <b-button
            ref="cancel"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            class="mx-1"
            variant="outline-danger"
            size="sm"
            aria-describedby="cancel-label"
            @click="cancelDelete"
          >
            Cancelar
          </b-button>
        </div>
      </template>
    </b-overlay>

  </div>
</template>

<script>
import {
  BCard, BTable, BDropdown, BDropdownItem, BPagination, BRow, BCol, BFormInput, BButton, BLink, BOverlay, BIcon,
} from 'bootstrap-vue'
import { title } from '@core/utils/filter'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import vSelect from 'vue-select'

export default {
  directives: {
    Ripple,
  },
  components: {
    BCard,
    BRow,
    BCol,
    // BFormInput,
    BButton,
    BTable,
    // BMedia,
    // BAvatar,
    BLink,
    // BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormInput,
    BOverlay,
    BIcon,
    // vSelect,

  },
  data() {
    return {
      toDelete: {},
      deleteWarning: false,
      search: '',
      currentPage: 1,
      perPage: 1,
      totalUsers: 100,
      sortBy: null,
      isSortDirDesc: null,
      tableColumns: [
        {
          key: 'full_name',
          // sortable: true,
          label: 'Administrador',
          formatter: title,
        },
        {
          key: 'email',
          // sortable: true,
          label: 'E-Mail',
        },
        { key: 'actions' },
      ],
    }
  },
  watch: {
    currentPage(newV) {
      this.$store.commit('admins/setPage', newV)
      this.$store.dispatch('admins/getClients')
    },
    search(newV) {
      this.$store.commit('admins/searchFilter', newV)
      this.$store.dispatch('admins/getClients')
    },
  },
  beforeCreate() {
    this.$store.dispatch('admins/getClients')
  },
  methods: {
    cancelDelete() {
      this.toDelete = {}
      this.deleteWarning = false
    },
    validarDelete(userId) {
      [this.toDelete] = this.$store.state.admins.clients.filter(c => c.id === userId)
      this.deleteWarning = true
    },
    deletar() {
      this.$store.dispatch('admins/deleteClient', this.toDelete).then(() => {
        this.toDelete = {}
        this.deleteWarning = false
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Admin',
            icon: 'UserCheckIcon',
            variant: 'danger',
            text: 'Administrador deletado',
          },
        })
        this.$store.dispatch('admins/getClients')
      })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Admin',
              icon: 'UserCheckIcon',
              variant: 'warning',
              text: 'Não conseguimos deletar este administrador',
            },
          })
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
